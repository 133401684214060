import { useContext } from 'react'

import { ILockedCardData, TopThreeMatches } from '@unionco/alaris-app-types'

import { IAMContext, TIAMContext } from 'context'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { StyledButton, UnlockableAreaCard } from 'components'

import { IPhaseKeyData } from '../UnlockableCards/UnlockableCards'

import { processTopMatches } from 'utils'

export type TSellerPotentialMatchesData = ILockedCardData & {
  matches: TopThreeMatches | [] | null
}

interface IPotentialMatchesProps {
  data: TSellerPotentialMatchesData
  phaseData: IPhaseKeyData[]
}

export const PotentialMatches: React.FC<IPotentialMatchesProps> = ({
  data: { info, matches, unlockKey },
  phaseData
}) => {
  const {
    IAMData: { progression }
  } = useContext(IAMContext) as TIAMContext
  const { navTrackingOnClick } = useNavigationClickTracking()
  /**
   * If progression key is not first key then user is past first section
   */
  const phaseKeys = phaseData.map((p) => p.key)
  const progressionPhaseIndex = phaseKeys.indexOf(progression.phase)
  const unlockKeyIndex = phaseKeys.indexOf(unlockKey)
  /**
   * If a seller is past phase 1, they will have matches, and we can show the matches
   * card on their dashboard, for example if it is a live session during impersonate mode.
   */
  const locked =
    progressionPhaseIndex <= unlockKeyIndex &&
    (!matches || matches.length === 0)

  // Process matches
  const topMatches = matches ? processTopMatches(matches) : [];

  const unlockedContent = topMatches ? (
    <>
      {/* TODO: Reenable with new V7 matches data */}
      {/* {topMatches.map((match, index) => {
        return <MatchLink data={match} key={`PotentialMatch_${index}`} />
      })} */}

      <StyledButton
        type='a'
        href={'/matchesv7'}
        theme='primary'
        themeStyle='outline'
        className='u-justify-center u-text-center'
        onClick={() =>
          navTrackingOnClick('explore matches', 'unlockable matches')
        }
      >
        Explore Matches
      </StyledButton>
    </>
  ) : (
    <p>No matches found.</p>
  )

  return (
    <UnlockableAreaCard
      title='Potential Matches'
      locked={locked}
      lockedMessage={'IOS 1'}
      info={info}
    >
      {unlockedContent}
    </UnlockableAreaCard>
  )
  return null
}

export default PotentialMatches
